import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  meterDataList: []
};

const meterDataViewerSlice = createSlice({
  name: 'meterDataViewer',
  initialState,
  reducers: {
    setAllMeterDataList: (state, action) => {
      state.meterDataList = action.payload;
    }
  }
});

export const { setAllMeterDataList } = meterDataViewerSlice.actions;
export default meterDataViewerSlice.reducer;
