import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const DefaultTemplatefields = ({
  headerParametersError,
  headerParameters,
  onSelectId,
  select,
  selectMenu,
  onChangeHandler,
  headerDetails,
  onRemoveHandler,
  addHeaderData,
  setShowDefaultFields,
  showDefaultFields,
}) => {
  return (
    <>
      {showDefaultFields && headerDetails?.length !== select?.length && (
        <Grid container position={'relative'}>
          <Grid
            md={4}
            xs={12}
            xl={4}
            marginY={2}
            paddingRight={{ xs: 0, md: 2 }}
          >
            <FormControl
              fullWidth
              error={!!headerParametersError?.id}
              sx={{ color: '#111' }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ color: '#111' }}>
                Parameter
              </InputLabel>
              <Select
                placeholder="Parameter"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Parameter"
                name="id"
                value={headerParameters.id}
                onChange={(event: SelectChangeEvent) => onSelectId(event, null)}
                sx={{
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300 // Set your desired height here
                    }
                  }
                }}
              >
                {select?.map((ite, startIndex) => (
                  <MenuItem
                    value={ite?.code}
                    id={ite?.code}
                    key={startIndex}
                    sx={{
                      display: selectMenu.includes(ite?.code) ? 'none' : 'block'
                    }}
                  >
                    {ite?.name}
                  </MenuItem>
                ))}
              </Select>
              {!!headerParametersError.id && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid md={4} paddingX={{ xs: 0, md: 2 }} xs={12} xl={4} marginY={2}>
            <TextField
              label="Start Index"
              fullWidth
              value={headerParameters?.startIndex}
              name="startIndex"
              type="number"
              onChange={(e) => onChangeHandler(e, null, null)}
              error={!!headerParametersError.startIndex}
              helperText={headerParametersError.startIndex}
              sx={{
                input: {
                  color: '#111',
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                },
                label: { color: '#111' }
              }}
            />
          </Grid>

          <Grid
            md={4}
            xs={12}
            xl={4}
            marginY={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingLeft={{ xs: 0, md: 2 }}
          >
            <TextField
              label="Length"
              fullWidth
              name="length"
              type="number"
              value={headerParameters?.length}
              onChange={(e) => onChangeHandler(e, null, null)}
              error={!!headerParametersError.length}
              helperText={headerParametersError.length}
              sx={{
                input: {
                  color: '#111',
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                },
                label: { color: '#111' }
              }}
            />
          </Grid>

          <Box
            position={'absolute'}
            display={'flex'}
            right={'-60px'}
            top={'34px'}
          >
            {headerDetails?.length > 0 && (
              <Box
                marginLeft={1}
                onClick={() => {
                  onRemoveHandler(headerParameters?.id, true);
                  setShowDefaultFields(false);
                }}
              >
                <RemoveIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
              </Box>
            )}
            <Box marginLeft={1} onClick={() => addHeaderData()}>
              <AddIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default DefaultTemplatefields;
