import {
  setAllMeterTypes,
  setAllTemplateList,
  setDataFormatsList,
  setMeterCategoryList,
  setMeterProfilesList,
  setSubjectTypesList,
  setTemplateById,
  setTemplateHeader,
  setTemplateHeadersList,
  setTemplateMeterData,
  setTemplateParametersList
} from 'src/reducers/Templates';
import api from '../index';

export const getAllTemplates: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/Template/GetTemplates?PageSize=${200}`);
      dispatch(setAllTemplateList(res?.data?.templateList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      // console.log('err', error);
      reject(error);
    }
  });
};

export const addNewTemplate: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post('/Template/CreateTemplate', apiData);
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getTemplateById: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/Template/GetTemplateByTemplateId?Id=${apiData}`
      );
      dispatch(setTemplateById(res.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const EditTemplate: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post('/Template/UpdateTemplate', apiData);
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getHeaderParameters: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get('/MeterHeader/GetMeterHeader');
      dispatch(setTemplateHeadersList(res?.data?.headerList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};
export const getAllTemplateParameters: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/MeterEntity/GetMeterEntities?PageNumber=1&pageSize=100000`
      );
      dispatch(setTemplateParametersList(res?.data?.entityList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getAllMeterProfiles: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get('/MeterProfiles/GetMeterProfiles');
      dispatch(setMeterProfilesList(res?.data?.profileList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getAllMeterTypes: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/MeterType/GetMeterType`);
      dispatch(setAllMeterTypes(res.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getAllDataFormats: any = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(`/DataFormat/GetDataFormat`);
      dispatch(setDataFormatsList(res?.data?.dataFormatList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getAllSubjectTypes: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `MeterSubjectType/GetMeterSubjectType?TemplateId=${apiData?.templateId}`
      );
      dispatch(setSubjectTypesList(res?.data?.meterSubjectTypeList));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const getTemplateHeaders: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/TemplateHeader/GetTemplateHeaders?TemplateId=${apiData?.tId}&SubjectTypeName=${apiData?.mId}&IsNewTemplate=${apiData?.nId}`
      );
      dispatch(setTemplateHeader(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const updateTemplateHeaders: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          '/TemplateHeader/UpdateTemplateHeaders',
          apiData
        );
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        reject(error);
      }
    });
  };

export const getTemplateMeterData: any = (id) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        `/MeterSubTemplate/GetMeterSubTemplate?TemplateId=${id?.templateId}&ProfileType=${id?.profileId}&MeterCategory=${id?.meterCategory}&SubjectTypeName=${id?.subjectCode}&IsNewTemplate=${id?.IsNewTemplate}`
      );
      dispatch(setTemplateMeterData(res?.data));
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const updateTemplateMeterData: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.post(
          '/MeterSubTemplate/UpdateMeterSubtemplate',
          apiData
        );
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        reject(error);
      }
    });
  };
export const getAllMeterCategory: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get('/MeterCategory/GetMeterCategory');
      resolve(res.data);
      dispatch(setMeterCategoryList(res.data.meterCategoryList));
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};

export const deleteMeterProfileData: any =
  (apiData) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete(
          `/MeterSubTemplate/DeleteMeterSubtemplate`,
          {
            data: apiData
          }
        );
        resolve(res.data);
      } catch (error) {
        //@ts-ignore
        reject(error);
      }
    });
  };

export const deleteTemplate: any = (apiData) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(`/Template/DeleteTemplate`, {
        data: apiData
      });
      resolve(res.data);
    } catch (error) {
      //@ts-ignore
      reject(error);
    }
  });
};
