import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const DefaultMeterDataFields = ({
  headerParametersError,
  headerParameters,
  onSelectId,
  select,
  selectMenu,
  onChangeHandler,
  headerDetails,
  onRemoveHandler,
  addHeaderData,
  setShowDefaultFields,
  showDefaultFields,
  dataFormatsList
}) => {
  return (
    <>
      {showDefaultFields && headerDetails?.length !== select?.length && (
        <Grid container position={'relative'}>
          {/* Parameter */}
          <Grid
            md={3}
            xs={12}
            xl={3}
            marginY={2}
            paddingRight={{ xs: 0, md: 1 }}
          >
            <FormControl
              fullWidth
              error={!!headerParametersError?.id}
              sx={{ color: '#111' }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ color: '#111' }}>
                Parameter
              </InputLabel>
              <Select
                placeholder="Parameter"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Parameter"
                name="id"
                value={headerParameters.id}
                onChange={(event: SelectChangeEvent) => onSelectId(event, null)}
                sx={{
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300 // Set your desired height here
                    }
                  }
                }}
              >
                {select?.map((ite, startIndex) => (
                  <MenuItem
                    value={ite?.obisCode}
                    id={ite?.obisCode}
                    key={startIndex}
                    sx={{
                      display: selectMenu.includes(ite?.obisCode)
                        ? 'none'
                        : 'block'
                    }}
                  >
                    {ite?.name}
                  </MenuItem>
                ))}
              </Select>
              {!!headerParametersError.id && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* Data Format */}
          <Grid md={3} xs={12} xl={3} marginY={2} paddingX={{ xs: 0, md: 2 }}>
            <FormControl
              fullWidth
              error={!!headerParametersError?.dataFormat}
              sx={{ color: '#111' }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ color: '#111' }}>
                Data Format
              </InputLabel>
              <Select
                placeholder="Data Format"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Data Format"
                name="dataFormat"
                value={headerParameters.dataFormat}
                onChange={(e) => onChangeHandler(e, null, null)}
                sx={{
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300 // Set your desired height here
                    }
                  }
                }}
              >
                {dataFormatsList?.map((ite, startIndex) => (
                  <MenuItem value={ite?.name} id={ite?.name} key={startIndex}>
                    {ite?.name}
                  </MenuItem>
                ))}
              </Select>
              {!!headerParametersError.dataFormat && (
                <FormHelperText>This field is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* Index */}
          <Grid md={2} paddingX={{ xs: 0, md: 1 }} xs={12} xl={2} marginY={2}>
            <TextField
              label="Start Index"
              fullWidth
              value={headerParameters?.startIndex}
              name="startIndex"
              type="number"
              onChange={(e) => onChangeHandler(e, null, null)}
              error={!!headerParametersError.startIndex}
              helperText={headerParametersError.startIndex}
              sx={{
                input: {
                  color: '#111',
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                },
                label: { color: '#111' }
              }}
            />
          </Grid>

          {/* Length */}
          <Grid
            md={2}
            xs={12}
            xl={2}
            marginY={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingX={{ xs: 0, md: 1 }}
          >
            <TextField
              label="Length"
              fullWidth
              name="length"
              type="number"
              value={headerParameters?.length}
              onChange={(e) => onChangeHandler(e, null, null)}
              error={!!headerParametersError.length}
              helperText={headerParametersError.length}
              sx={{
                input: {
                  color: '#111',
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                },
                label: { color: '#111' }
              }}
            />
          </Grid>

          {/* Scaler */}
          <Grid
            md={2}
            xs={12}
            xl={2}
            marginY={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingLeft={{ xs: 0, md: 1 }}
          >
            <TextField
              label="Scaler"
              fullWidth
              name="scaler"
              type="number"
              value={headerParameters?.scaler}
              onChange={(e) => onChangeHandler(e, null, null)}
              error={!!headerParametersError.scaler}
              helperText={headerParametersError.scaler}
              sx={{
                input: {
                  color: '#111',
                  '&::placeholder': {
                    color: '#111',
                    opacity: 1
                  }
                },
                label: { color: '#111' }
              }}
            />
          </Grid>

          <Box
            position={'absolute'}
            display={'flex'}
            right={'-60px'}
            top={'34px'}
          >
            {headerDetails?.length > 0 && (
              <Box
                marginLeft={1}
                onClick={() => {
                  onRemoveHandler(headerParameters?.id, true);
                  setShowDefaultFields(false);
                }}
              >
                <RemoveIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
              </Box>
            )}
            <Box marginLeft={1} onClick={() => addHeaderData()}>
              <AddIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default DefaultMeterDataFields;
