import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FC } from 'react';
interface filledTemplate {
  startIndex: number;
  item: { id: string };
  onSelectId: () => void;
  select: [];
  selectMenu: {};
  onChangeHandler: any;
  onRemoveHandler: any;
  showDefaultFields: any;
  length: any;
  setShowDefaultFields: any;
}

const dataFormatOptions = [
  {
    id: 'text',
    name: 'Text'
  },
  {
    id: 'decimal',
    name: 'Decimal'
  },
  {
    id: 'integer',
    name: 'Integer'
  },
  {
    id: 'timestamp',
    name: 'Timestamp'
  }
];


const FilledMeterDataFields = ({
  startIndex,
  item,
  onSelectId,
  select,
  selectMenu,
  onChangeHandler,
  onRemoveHandler,
  showDefaultFields,
  length,
  setShowDefaultFields,
  dataFormatsList,
  isDisabled,
}) => {
  return (
    <>
      <Grid container position={'relative'}>
        {/* Parameter */}
        <Grid md={3} paddingRight={{ xs: 0, md: 1 }} xs={12} xl={3} marginY={2}>
          <FormControl
            fullWidth
            className={` ${isDisabled ? 'custom-design' : ''}`}
          >
            <InputLabel id="demo-simple-select-label">Parameter</InputLabel>
            <Select
              disabled={isDisabled}
              fullWidth
              name="id"
              placeholder="Parameters"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Parameter"
              value={item?.id || ''}
              onChange={(event: SelectChangeEvent) =>
                onSelectId(event, item?.id)
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300
                  }
                }
              }}
            >
              {select?.map((ite, startIndex) => (
                <MenuItem
                  value={ite?.obisCode}
                  key={startIndex}
                  id={ite?.obisCode}
                  sx={{
                    display: selectMenu.includes(ite?.obisCode)
                      ? 'none'
                      : 'block'
                  }}
                >
                  {ite?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Data Format */}
        <Grid md={3} paddingX={{ xs: 0, md: 2 }} xs={12} xl={3} marginY={2}>
          <FormControl
            fullWidth
            className={` ${isDisabled ? 'custom-design' : ''}`}
          >
            <InputLabel id="demo-simple-select-label">Data Format</InputLabel>
            <Select
              disabled={isDisabled}
              fullWidth
              name="dataFormat"
              placeholder="Data Format"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Data Format"
              value={item?.dataFormat || ''}
              onChange={(e) => onChangeHandler(e, item?.id, startIndex)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300 // Set your desired height here
                  }
                }
              }}
            >
              {dataFormatsList?.map((ite, startIndex) => (
                <MenuItem
                  value={ite?.name}
                  key={startIndex}
                  id={ite?.name}
                  //   sx={{
                  //     display: selectMenu.includes(ite?.id) ? 'none' : 'block'
                  //   }}
                >
                  {ite?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Index */}
        <Grid md={2} paddingX={{ xs: 0, md: 1 }} xs={12} xl={2} marginY={2}>
          <TextField
            disabled={isDisabled}
            label="Start Index"
            fullWidth
            name="startIndex"
            value={item?.startIndex}
            type="number"
            onChange={(e) => onChangeHandler(e, item?.id, startIndex)}
            error={item?.startIndex === '' ? true : false}
            helperText={item?.startIndex === '' ? item?.error?.startIndex : ''}
            className={` ${isDisabled ? 'custom-design' : ''}`}
          />
        </Grid>

        {/* Length */}
        <Grid
          md={2}
          paddingX={{ xs: 0, md: 1 }}
          xs={12}
          xl={2}
          marginY={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            disabled={isDisabled}
            label="Length"
            fullWidth
            name="length"
            type="number"
            value={item?.length}
            onChange={(e) => onChangeHandler(e, item?.id, startIndex)}
            error={!item?.length ? true : false}
            helperText={!item?.length ? item?.error?.length : ''}
            className={` ${isDisabled ? 'custom-design' : ''}`}
          />
        </Grid>

        {/* Scaler */}
        <Grid
          md={2}
          paddingLeft={{ xs: 0, md: 1 }}
          xs={12}
          xl={2}
          marginY={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            disabled={isDisabled}
            label="Scaler"
            fullWidth
            name="scaler"
            type="number"
            value={item?.scaler}
            onChange={(e) => onChangeHandler(e, item?.id, startIndex)}
            error={item?.scaler === '' ? true : false}
            helperText={item?.scaler === '' ? item?.error?.scaler : ''}
            className={` ${isDisabled ? 'custom-design' : ''}`}
          />
        </Grid>

        {!isDisabled && (
          <Box
            position={'absolute'}
            right={'-60px'}
            top={'34px'}
            display={'flex'}
          >
            {(length !== 1 || showDefaultFields) && (
              <Box marginLeft={1} onClick={() => onRemoveHandler(item?.id)}>
                <RemoveIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
              </Box>
            )}
            {length === startIndex + 1 && !showDefaultFields && (
              <Box marginLeft={1} onClick={() => setShowDefaultFields(true)}>
                <AddIcon fontSize="medium" sx={{ cursor: 'pointer' }} />
              </Box>
            )}
          </Box>
        )}
      </Grid>
    </>
  );
};

export default FilledMeterDataFields;
