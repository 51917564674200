import { configureStore } from '@reduxjs/toolkit';
import templateSlice from '../reducers/Templates/index';
import meterDataViewerSlice from '../reducers/MeterData/index';
import rawDataViewerSlice from '../reducers/RawData/index';
import slaViewerSlice from '../reducers/SlaData/index';

export const store = configureStore({
  reducer: {
    template: templateSlice,
    meterData: meterDataViewerSlice,
    rawData: rawDataViewerSlice,
    slaData: slaViewerSlice
  }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
