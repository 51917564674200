import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import ManageHeader from './views/pages/Templates/ManageHeader/ManageHeader';
import ManageMeterData from './views/pages/Templates/ManageMeterData/ManageMeterData';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Templates = Loader(
  lazy(() => import('src/views/pages/Templates/Template'))
);
const MeterEntityViewer = Loader(
  lazy(() => import('src/views/pages/Meter Entity Viewer/index'))
);
const RawDataViewer = Loader(
  lazy(() => import('src/views/pages/Raw Data Viewer/index'))
);
const MeteringDataViewer = Loader(
  lazy(() => import('src/views/pages/Metering Data Viewer/index'))
);
const SLADataViewer = Loader(
  lazy(() => import('src/views/pages/SLA Data Viewer/index'))
);
// Status
const Status404 = Loader(
  lazy(() => import('src/views/pages/Status/Status404'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/templates" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'templates',
        element: <Templates />
      },
      {
        path: 'add-template-header/:id/:mId/:nId',
        element: <ManageHeader />
      },
      {
        path: 'manage-meter-data/:tId/:pId/:mId/:sId/:nId',
        element: <ManageMeterData />
      },
      {
        path: 'raw-data-viewer',
        element: <RawDataViewer />
      },
      {
        path: 'mater-entity-viewer',
        element: <MeterEntityViewer />
      },
      {
        path: 'meter-data-viewer',
        element: <MeteringDataViewer />
      },
      {
        path: 'sla-data-viewer',
        element: <SLADataViewer />
      }
    ]
  }
];

export default routes;
