import React, { useEffect, useState } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  TextField,
  createTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import TemplateNameCommon from 'src/components/TemplateFields/TemplateNameCommon';
import HeaderFieldsLabel from 'src/components/TemplateFields/HeaderFieldsLabel';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  deleteMeterProfileData,
  getAllDataFormats,
  getAllTemplateParameters,
  getTemplateMeterData,
  updateTemplateMeterData
} from 'src/api/Templates';
import FilledTemplateFields from 'src/components/TemplateFields/FilledTemplateFields';
import DefaultTemplatefields from 'src/components/TemplateFields/DefaultTemplatefields';
import CommonButton from 'src/components/CommonButton';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal';
import { filledFormData, finalFormData } from 'src/utils/helper';
import DeleteConfirmationModal from 'src/components/Modals/DeleteConfirmation';
import FilledMeterDataFields from 'src/components/TemplateFields/FilledMeterDataFields';
import DefaultMeterDataFields from 'src/components/TemplateFields/DefaultMeterDataFields';
import MeterHeaderFields from 'src/components/TemplateFields/MeterHeaderFields';

interface parameters {
  id?: string;
  length?: string;
  startIndex?: string;
  meterCategory?: string;
  templateId?: string;
  profileType?: string;
  scaler?: string;
  dataFormat?: string;
}

const ManageMeterData = () => {
  const paramas = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = createTheme();

  const { addToast } = useToasts();

  const { parametersList } = useSelector((state: any) => state.template);
  const { templateMeterData } = useSelector((state: any) => state.template);
  const { dataFormatsList } = useSelector((state: any) => state.template);

  const [entityList, setEntityList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showDefaultFields, setShowDefaultFields] = useState(true);
  const [addParametersButton, setAddParameterButton] = useState<Boolean>(true);
  const [headerParameters, setHeaderParameters] = useState<parameters>({
    id: null,
    length: '',
    startIndex: '',
    meterCategory: paramas?.mId,
    profileType: '',
    templateId: '',
    scaler: '',
    dataFormat: ''
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(true);
  const [headerParametersError, setHeaderParametersError] =
    useState<parameters>({
      id: null,
      length: '',
      startIndex: '',
      scaler: '',
      dataFormat: ''
    });
  const [headerDetails, setHeaderDetails] = useState([]);
  const [selectMenu, setSelectMenu] = useState([]);

  useEffect(() => {
    if (parametersList.length > 0) {
      const sortedData = parametersList.slice().sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setEntityList(sortedData);
    }
  }, [parametersList]);

  useEffect(() => {
    let obj = {
      templateId: paramas?.tId,
      profileId: paramas?.pId,
      meterCategory: paramas?.mId,
      subjectCode: paramas?.sId,
      IsNewTemplate: paramas?.nId
    };
    dispatch(getTemplateMeterData(obj))
      .then((res) => {
        setCardLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
    dispatch(getAllTemplateParameters());
    dispatch(getAllDataFormats());
  }, []);

  useEffect(() => {
    if (templateMeterData?.meterSubTemplates?.length > 0) {
      setIsDisabled(true);
      let ids = [];
      templateMeterData?.meterSubTemplates?.forEach((ite) =>
        ids.push(ite.obiscode)
      );
      setSelectMenu(ids);
      setHeaderDetails(filledFormData(templateMeterData?.meterSubTemplates));
      setShowDefaultFields(false);
    } else {
      setHeaderDetails([]);
      setShowDefaultFields(true);
      setIsDisabled(false);
    }
  }, [templateMeterData?.meterSubTemplates]);

  const addHeaderData = () => {
    const newErrors = {
      id: null,
      length: '',
      startIndex: '',
      scaler: '',
      dataFormat: ''
    };
    if (!headerParameters?.id) {
      newErrors.id = 'This field is required';
    }
    if (!headerParameters?.length) {
      newErrors.length = 'This field is required';
    }
    if (!headerParameters?.startIndex) {
      newErrors.startIndex = 'This field is required';
    }
    if (!headerParameters?.scaler) {
      newErrors.scaler = 'This field is required';
    }
    if (!headerParameters?.dataFormat) {
      newErrors.dataFormat = 'This field is required';
    }
    setHeaderParametersError(newErrors);
    if (
      headerParameters?.id &&
      headerParameters?.startIndex &&
      headerParameters?.length &&
      headerParameters?.scaler &&
      headerParameters?.dataFormat
    ) {
      setHeaderDetails([...headerDetails, headerParameters]);
      const ids = [];
      const data = [...headerDetails, headerParameters];
      data.forEach((item) => ids.push(item.id));
      setSelectMenu(ids);
      setHeaderParameters({
        id: null,
        length: '',
        startIndex: '',
        scaler: '',
        dataFormat: null
      });
    }
  };

  const onSelectId = (event, oldId) => {
    const isName = document.getElementById(event.target.value).innerText;
    let parameterIds = [];
    if (oldId) {
      const updatedDetails = headerDetails.map((item) =>
        item.id === oldId
          ? {
              ...item,
              [event.target.name]: event.target.value,
              // name: isName,
              templateId: paramas?.tId,
              profileType: paramas?.pId,
              meterCategory: paramas?.mId
            }
          : item
      );
      setHeaderDetails(updatedDetails);
      updatedDetails.forEach((item) => parameterIds.push(item?.id));
      setSelectMenu([...parameterIds, headerParameters?.id]);
    } else {
      setHeaderParametersError({
        ...headerParametersError,
        [event.target.name]: ''
      });
      setHeaderParameters({
        ...headerParameters,
        id: event.target.value,
        meterCategory: paramas?.mId,
        templateId: paramas?.tId,
        profileType: paramas?.pId
      });
      headerDetails.forEach((item) => parameterIds.push(item?.id));
      setSelectMenu([...parameterIds, event.target.value]);
    }
  };

  const onChangeHandler = (e, id, startIndex) => {
    const { name, value } = e.target;
    if (id) {
      if (!value) {
        const updatedDetails = headerDetails.map((item) =>
          item.id === id
            ? {
                ...item,
                [name]: value,
                error: {
                  startIndex: 'This field is required',
                  length: 'This field is required',
                  scaler: 'This field is required'
                }
              }
            : item
        );
        setHeaderDetails(updatedDetails);
      } else {
        // debugger;
        const updatedDetails1 = headerDetails.map((item) =>
          item.id === id
            ? {
                ...item,
                [name]: value,
                error: {
                  length: !item?.length ? item?.error?.length : '',
                  startIndex: !item?.startIndex ? item?.error?.startIndex : '',
                  scaler: !item?.scaler ? item?.error?.scaler : ''
                }
              }
            : item
        );
        setHeaderDetails(updatedDetails1);
      }
    } else {
      setHeaderParameters({ ...headerParameters, [name]: value });
      setHeaderParametersError({ ...headerParametersError, [name]: '' });
    }
  };

  const onRemoveHandler = (id, isRemove = false) => {
    if (isRemove) {
      setHeaderParameters({ id: null, length: '', startIndex: '' });
    } else {
      const data = headerDetails.filter((item) => item?.id !== id);
      setHeaderDetails(data);
      if (data.length > 1) setShowDefaultFields(false);
    }

    const ids = selectMenu.filter((item) => item !== id);
    setSelectMenu(ids);
  };

  const formSubmithandler = (e) => {
    e.preventDefault();
    let brack = false;
    headerDetails.forEach((item) => {
      if (item?.error?.startIndex && item?.error?.length) {
        brack = true;
      }
    });
    if (brack) {
      addToast('Please fill all the fields!', {
        appearance: 'warning'
      });
      return false;
    }

    const key = 'error';
    headerDetails.map((obj) => {
      delete obj[key];
      return obj;
    });

    if (showDefaultFields) {
      const newErrors = {
        id: null,
        length: '',
        startIndex: '',
        scaler: '',
        dataFormat: ''
      };
      if (!headerParameters?.id) {
        newErrors.id = 'This field is required';
      }
      if (!headerParameters?.length) {
        newErrors.length = 'This field is required';
      }
      if (!headerParameters?.startIndex) {
        newErrors.startIndex = 'This field is required';
      }
      if (!headerParameters?.scaler) {
        newErrors.scaler = 'This field is required';
      }
      if (!headerParameters?.dataFormat) {
        newErrors.dataFormat = 'This field is required';
      }
      setHeaderParametersError(newErrors);

      if (
        headerParameters?.id &&
        headerParameters?.startIndex &&
        headerParameters?.length &&
        headerParameters?.scaler &&
        headerParameters?.dataFormat
      ) {
        const modifiedArray = finalFormData(
          [...headerDetails, headerParameters],
          'id',
          'obisCode'
        );
        const newArrayWithSubjectedId = modifiedArray.map((obj) => ({
          ...obj,
          subjectTypeName: localStorage?.subjectTypes,
          isNewTemplate: JSON.parse(paramas?.nId)
        }));

        let obj = {
          meterSubTemplates: newArrayWithSubjectedId
        };

        if (templateMeterData?.meterSubTemplates?.length > 0) {
          setConfirmModalOpen(true);
          setFormData(obj);
        } else {
          dispatch(updateTemplateMeterData(obj))
            .then((res) => {
              navigate('/templates');
              setIsLoading(false);
              setConfirmModalOpen(false);
              addToast('Meter Data Updated Successfully', {
                appearance: 'success'
              });
            })
            .catch((err) => {
              addToast('Something went wrong!', {
                appearance: 'error'
              });
              setConfirmModalOpen(false);
              setIsLoading(false);
            });
        }
      }
    } else {
      // setIsLoading(true);
      const modifiedArray = finalFormData(
        [...headerDetails, headerParameters],
        'id',
        'obisCode'
      );
      const newArrayWithSubjectedId = modifiedArray.map((obj) => ({
        ...obj,
        subjectTypeName: localStorage?.subjectTypes,
        isNewTemplate: JSON.parse(paramas?.nId)
      }));

      let obj = {
        meterSubTemplates: newArrayWithSubjectedId
      };
      // Remove the last object from formData.headerDetails
      const updatedHeaderDetails = (obj as any).meterSubTemplates?.slice(0, -1);
      const updatedFormData = {
        ...(obj as any),
        meterSubTemplates: updatedHeaderDetails
      };
      if (templateMeterData?.meterSubTemplates?.length > 0) {
        setConfirmModalOpen(true);
        setFormData(updatedFormData);
      } else {
        dispatch(updateTemplateMeterData(obj))
          .then((res) => {
            navigate('/templates');
            setIsLoading(false);
            setConfirmModalOpen(false);
            addToast('Meter Data Updated Successfully', {
              appearance: 'success'
            });
          })
          .catch((err) => {
            addToast('Something went wrong!', {
              appearance: 'error'
            });
            setConfirmModalOpen(false);
            setIsLoading(false);
          });
      }
    }
  };

  const handleModalSubmit = () => {
    setIsLoading(true);
    dispatch(updateTemplateMeterData(formData))
      .then((res) => {
        navigate('/templates');
        setIsLoading(false);
        setConfirmModalOpen(false);
        addToast('Meter Data Updated Successfully', {
          appearance: 'success'
        });
      })
      .catch((err) => {
        addToast('Something went wrong!', {
          appearance: 'error'
        });
        setConfirmModalOpen(false);
        setIsLoading(false);
      });
  };

  const handleDeleteModalSubmit = () => {
    setDeleteLoading(true);
    let obj = {
      templateId: Number(paramas?.tId),
      profileType: paramas?.pId,
      subjectTypeName: localStorage?.subjectTypes,
      isNewTemplate: JSON.parse(paramas?.nId),
      meterCategory: paramas?.mId
    };
    dispatch(deleteMeterProfileData(obj))
      .then((res) => {
        navigate('/templates');
        setDeleteLoading(false);
        addToast('Meter Profile Data Deleted Successfully', {
          appearance: 'success'
        });
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };

  const clearAllHandler = () => {
    setAddParameterButton(true);
    setHeaderDetails([]);
    setHeaderParameters({ id: null, length: '', startIndex: '' });
    setShowDefaultFields(true);
  };

  const deleteHandler = () => {
    setDeleteModalOpen(true);
  };

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg" sx={{ marginY: '50px' }}>
        <form onSubmit={formSubmithandler}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: {
                    xs: '45px 70px',
                    md: '45px 80px'
                  },
                  [theme.breakpoints.up('md')]: {
                    padding: '45px 80px'
                  }
                }}
              >
                {cardLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    {/* Template Name */}
                    <TemplateNameCommon show={false} />

                    {/*  //Profile Name */}
                    <Grid
                      container
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid md={4} paddingRight={4} xs={12} xl={3} marginY={0}>
                        {' '}
                        <Box
                          sx={{
                            border: 'none',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '700'
                          }}
                        >
                          <h3
                            style={{
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont'
                            }}
                          >
                            Profile Name
                          </h3>
                        </Box>
                      </Grid>
                      <Grid md={8} xs={12} xl={9} marginY={2} paddingRight={0}>
                        <TextField
                          disabled={true}
                          fullWidth
                          value={paramas?.pId}
                          className="custom-design"
                        />
                      </Grid>

                      {/*  //Meter Type */}
                      <Grid md={4} paddingRight={4} xs={12} xl={3} marginY={0}>
                        {' '}
                        <Box
                          sx={{
                            border: 'none',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '700'
                          }}
                        >
                          <h3
                            style={{
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont'
                            }}
                          >
                            Meter Category
                          </h3>
                        </Box>
                      </Grid>
                      <Grid md={8} xs={12} xl={9} marginY={2} paddingRight={0}>
                        <TextField
                          disabled={true}
                          fullWidth
                          value={paramas?.mId}
                          className="custom-design"
                        />
                      </Grid>

                      {/*  //Subject Type */}
                      <Grid md={4} paddingRight={4} xs={12} xl={3} marginY={0}>
                        {' '}
                        <Box
                          sx={{
                            border: 'none',
                            height: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: '700'
                          }}
                        >
                          <h3
                            style={{
                              fontFamily:
                                'Inter, -apple-system, BlinkMacSystemFont'
                            }}
                          >
                            Subject Type
                          </h3>
                        </Box>
                      </Grid>
                      <Grid md={8} xs={12} xl={9} marginY={2} paddingRight={0}>
                        <TextField
                          disabled={true}
                          fullWidth
                          value={localStorage?.subjectType}
                          className="custom-design"
                        />
                      </Grid>
                    </Grid>

                    {/* Header Parameters  */}
                    <MeterHeaderFields />

                    {addParametersButton && headerDetails?.length === 0 ? (
                      <Grid container paddingY={1} paddingRight={0}>
                        <Box
                          marginTop={6}
                          sx={{
                            width: '100%',
                            border: 'none',
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#D9D9D9',
                            borderRadius: '8px',
                            cursor: 'pointer'
                            // padding: '5px 20px'
                          }}
                          onClick={() => setAddParameterButton(false)}
                        >
                          <AddIcon fontSize="medium" />{' '}
                          <h3>Tap here to add parameters in Meter Data</h3>
                        </Box>
                      </Grid>
                    ) : (
                      // Loop Form data
                      <Grid container marginTop={2}>
                        {headerDetails?.map((item, startIndex) => (
                          <>
                            <FilledMeterDataFields
                              onSelectId={onSelectId}
                              item={item}
                              startIndex={startIndex}
                              select={entityList}
                              selectMenu={selectMenu}
                              onChangeHandler={onChangeHandler}
                              onRemoveHandler={onRemoveHandler}
                              showDefaultFields={showDefaultFields}
                              length={headerDetails?.length}
                              setShowDefaultFields={setShowDefaultFields}
                              isDisabled={isDisabled}
                              dataFormatsList={dataFormatsList}
                            />
                          </>
                        ))}

                        {/* Default Form */}
                        {entityList?.length > headerDetails?.length && (
                          <>
                            <DefaultMeterDataFields
                              headerParameters={headerParameters}
                              headerParametersError={headerParametersError}
                              onSelectId={onSelectId}
                              select={entityList}
                              selectMenu={selectMenu}
                              onChangeHandler={onChangeHandler}
                              headerDetails={headerDetails}
                              onRemoveHandler={onRemoveHandler}
                              addHeaderData={addHeaderData}
                              showDefaultFields={showDefaultFields}
                              setShowDefaultFields={setShowDefaultFields}
                              dataFormatsList={dataFormatsList}
                            />
                          </>
                        )}
                      </Grid>
                    )}
                    <Box
                      sx={{
                        marginTop: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {templateMeterData?.meterSubTemplates?.length >= 1 &&
                        isDisabled && (
                          <>
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="contained"
                              onClick={() => setIsDisabled(false)}
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="outlined"
                              onClick={() => navigate('/templates')}
                              startIcon={<CloseIcon />}
                            >
                              Close
                            </Button>
                            <Button
                              sx={{ width: 100 }}
                              variant="contained"
                              onClick={deleteHandler}
                              color="error"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      {!isDisabled && (
                        <>
                          {((headerParameters?.id &&
                            headerParameters?.startIndex &&
                            headerParameters?.length &&
                            headerParameters?.scaler &&
                            headerParameters?.dataFormat) ||
                            headerDetails?.length >= 1) && (
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="outlined"
                              onClick={clearAllHandler}
                            >
                              Clear All
                            </Button>
                          )}
                          <Box>
                            {((headerParameters?.id &&
                              headerParameters?.startIndex &&
                              headerParameters?.length &&
                              headerParameters?.scaler &&
                              headerParameters?.dataFormat) ||
                              headerDetails?.length > 0) && (
                              // <CommonButton
                              //   text="Save"
                              //   variant="contained"
                              //   type="submit"
                              // />
                              <Button
                                variant="contained"
                                type="submit"
                                sx={{ width: 100 }}
                              >
                                Save
                              </Button>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        </form>
        <ConfirmationModal
          confirmModalOpen={confirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          handleModalSubmit={handleModalSubmit}
          loading={isLoading}
        />
        <DeleteConfirmationModal
          confirmModalOpen={deleteModalOpen}
          setConfirmModalOpen={setDeleteModalOpen}
          handleModalSubmit={handleDeleteModalSubmit}
          loading={deleteLoading}
          description={
            ' Are you sure you want to delete the meter profile data?'
          }
        />
      </Container>
    </>
  );
};

export default ManageMeterData;
