export const finalFormData = (arrayOfObjects, oldKeyName, newKeyName) =>
  arrayOfObjects.map((obj) => {
    const { [oldKeyName]: id, ...rest } = obj;
    return {
      ...rest,
      [newKeyName]: id
    };
  });

export const filledFormData = (arrayOfObjects) => {
  const sortedArray = [...arrayOfObjects].sort(
    (a, b) => a.startIndex - b.startIndex
  );

  return sortedArray.map((obj) => {
    const { ['obiscode']: id, ...rest } = obj;
    return {
      ...rest,
      ['id']: id
    };
  });
};

export const preFilledFormData = (arrayOfObjects) => {
  const sortedArray = [...arrayOfObjects].sort(
    (a, b) => a.startIndex - b.startIndex
  );

  return sortedArray.map((obj) => {
    const { ['headerId']: id, ...rest } = obj;
    return {
      ...rest,
      ['id']: id
    };
  });
};

export const profilesEnum = {
  BLOCKLOADPROFILE: 1,
  BILLINGPROFILE: 2,
  DAILYLOADPROFILE: 3
};

export function isArrayEmptyOrNull(arr) {
  if (!Array.isArray(arr)) {
    return true;
  }
  for (const val of arr) {
    if (val !== null) {
      return false;
    }
  }
  return true;
}
