import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CommonButton from '../CommonButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    [theme.breakpoints.up('md')]: {
      width: '33%' // Adjust the width for small screens and above
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%' // Adjust the width for extra small screens
    },
    maxWidth: 'none' // Remove the maximum width limit
  }
}));

const DeleteConfirmationModal = ({
  confirmModalOpen,
  setConfirmModalOpen,
  handleModalSubmit,
  loading,
  description
}) => {
  return (
    <Box>
      <BootstrapDialog
        maxWidth="md"
        aria-labelledby="customized-dialog-title"
        open={confirmModalOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete Confirmation
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setConfirmModalOpen(false);
            }}
            sx={{ marginRight: '10px' }}
          >
            Close
          </Button>

          <CommonButton
            loading={loading}
            text="Save"
            variant="contained"
            type="submit"
            onClick={handleModalSubmit}
          />
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default DeleteConfirmationModal;
