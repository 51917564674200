import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  templateList: [],
  templateHeader: {},
  templateById: {},
  templateMeterData: {},
  parametersList: [],
  headersList: [],
  materProfilesList: [],
  materTypesList: [],
  materCategoryList: [],
  dataFormatsList: [],
  subjectTypesList: [],
};

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setAllTemplateList: (state, action) => {
      state.templateList = action.payload;
    },
    setTemplateHeader: (state, action) => {
      state.templateHeader = action.payload;
    },
    setTemplateById: (state, action) => {
      state.templateById = action.payload;
    },
    setTemplateParametersList: (state, action) => {
      state.parametersList = action.payload;
    },
    setMeterProfilesList: (state, action) => {
      state.materProfilesList = action.payload;
    },
    setDataFormatsList: (state, action) => {
      state.dataFormatsList = action.payload;
    },
    setSubjectTypesList: (state, action) => {
      state.subjectTypesList = action.payload;
    },
    setMeterCategoryList: (state, action) => {
      state.materCategoryList = action.payload;
    },
    setAllMeterTypes: (state, action) => {
      state.materTypesList = action.payload;
    },
    setTemplateMeterData: (state, action) => {
      state.templateMeterData = action.payload;
    },
    setTemplateHeadersList: (state, action) => {
      state.headersList = action.payload;
    }
  }
});

export const {
  setMeterProfilesList,
  setTemplateParametersList,
  setAllTemplateList,
  setTemplateMeterData,
  setTemplateHeader,
  setTemplateById,
  setTemplateHeadersList,
  setDataFormatsList,
  setSubjectTypesList,
  setAllMeterTypes,
  setMeterCategoryList
} = templateSlice.actions;
export default templateSlice.reducer;
