import { Box, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const MeterHeaderFields = () => {
  const Item = styled(Box)(({ theme }) => ({
    paddingX: theme.spacing(2),
    textAlign: 'center'
  }));

  return (
    <>
      <Grid
        container
        marginTop={3}
        // spacing={2}
        sx={{ border: '1px solid #D9D9D9', borderRadius: '0px' }}
      >
        <Grid md={3} xs={12} xl={3}>
          <Item
            sx={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <h4
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Parameter
            </h4>
          </Item>
        </Grid>
        <Grid md={3} xs={12} xl={3}>
          <Item
            sx={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <h4
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Data Format
            </h4>
          </Item>
        </Grid>

        <Grid md={2} xs={12} xl={2}>
          <Item
            sx={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <h4
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Start Index
            </h4>
          </Item>
        </Grid>

        <Grid md={2} xs={12} xl={2}>
          <Item
            sx={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <h4
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Length
            </h4>
          </Item>
        </Grid>
        <Grid md={2} xs={12} xl={2}>
          <Item
            sx={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px'
            }}
          >
            <h4
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Scaler
            </h4>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default MeterHeaderFields;
