import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  createTheme
} from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import AddIcon from '@mui/icons-material/Add';
import DefaultTemplatefields from 'src/components/TemplateFields/DefaultTemplatefields';
import FilledTemplateFields from 'src/components/TemplateFields/FilledTemplateFields';
import HeaderFieldsLabel from 'src/components/TemplateFields/HeaderFieldsLabel';
import TemplateNameCommon from 'src/components/TemplateFields/TemplateNameCommon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getAllTemplateParameters,
  getHeaderParameters,
  getTemplateHeaders,
  updateTemplateHeaders
} from 'src/api/Templates';
import { useToasts } from 'react-toast-notifications';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { finalFormData, preFilledFormData } from 'src/utils/helper';

interface parameters {
  id?: string;
  length?: string;
  startIndex?: string;
  headerName?: string;
}

const ManageHeader = () => {
  const paramas = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = createTheme();

  const { addToast } = useToasts();

  const { headersList } = useSelector((state: any) => state.template);
  const { templateHeader } = useSelector((state: any) => state.template);

  const [entityList, setEntityList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showDefaultFields, setShowDefaultFields] = useState(true);
  const [addParametersButton, setAddParameterButton] = useState<Boolean>(true);
  const [headerParameters, setHeaderParameters] = useState<parameters>({
    id: null,
    length: '',
    startIndex: '',
    headerName: ''
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(true);
  const [headerParametersError, setHeaderParametersError] =
    useState<parameters>({
      id: null,
      length: '',
      startIndex: ''
    });
  const [headerDetails, setHeaderDetails] = useState([]);
  const [selectMenu, setSelectMenu] = useState([]);

  useEffect(() => {
    if (headersList.length > 0) {
      const sortedData = headersList.slice().sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setEntityList(sortedData);
    }
  }, [headersList]);

  const addHeaderData = () => {
    const newErrors = {
      id: null,
      length: '',
      startIndex: ''
    };
    if (!headerParameters?.id) {
      newErrors.id = 'This field is required';
    }
    if (!headerParameters?.length) {
      newErrors.length = 'This field is required';
    }
    if (!headerParameters?.startIndex) {
      newErrors.startIndex = 'This field is required';
    }
    setHeaderParametersError(newErrors);
    if (
      headerParameters?.id &&
      headerParameters?.startIndex &&
      headerParameters?.length
    ) {
      setHeaderDetails([...headerDetails, headerParameters]);
      const ids = [];
      const data = [...headerDetails, headerParameters];
      data.forEach((item) => ids.push(item.id));
      setSelectMenu(ids);
      setHeaderParameters({ id: null, length: '', startIndex: '' });
    }
  };

  useEffect(() => {
    let obj = {
      tId: paramas?.id,
      mId: paramas?.mId,
      nId: paramas?.nId
    };
    dispatch(getTemplateHeaders(obj)).then((res) => {
      setCardLoading(false);
    });
    // dispatch(getAllTemplateParameters());
    dispatch(getHeaderParameters());
  }, []);

  useEffect(() => {
    if (templateHeader?.headerDetails?.length > 0) {
      setIsDisabled(true);
      let ids = [];
      templateHeader?.headerDetails?.forEach((ite) => ids.push(ite.headerId));
      setSelectMenu(ids);
      setHeaderDetails(preFilledFormData(templateHeader?.headerDetails));
      setShowDefaultFields(false);
    } else {
      setHeaderDetails([]);
      setShowDefaultFields(true);
      setIsDisabled(false);
    }
  }, [templateHeader?.headerDetails]);

  const onSelectId = (event, oldId) => {
    const isName = document.getElementById(event.target.value).innerText;
    let parameterIds = [];
    if (oldId) {
      const updatedDetails = headerDetails.map((item) =>
        item.id === oldId
          ? {
              ...item,
              [event.target.name]: event.target.value,
              headerName: isName
            }
          : item
      );
      setHeaderDetails(updatedDetails);
      updatedDetails.forEach((item) => parameterIds.push(item?.id));
      setSelectMenu([...parameterIds, headerParameters?.id]);
    } else {
      setHeaderParametersError({
        ...headerParametersError,
        [event.target.name]: ''
      });
      setHeaderParameters({
        ...headerParameters,
        id: event.target.value,
        headerName: isName
      });
      headerDetails.forEach((item) => parameterIds.push(item?.id));

      setSelectMenu([...parameterIds, event.target.value]);
    }
  };

  const onChangeHandler = (e, id, startIndex) => {
    const { name, value } = e.target;
    if (id) {
      if (!value) {
        const updatedDetails = headerDetails.map((item) =>
          item.id === id
            ? {
                ...item,
                [name]: value,
                error: {
                  startIndex: 'This field is required',
                  length: 'This field is required'
                }
              }
            : item
        );
        setHeaderDetails(updatedDetails);
      } else {
        const updatedDetails1 = headerDetails.map((item) =>
          item.id === id
            ? {
                ...item,
                [name]: value,
                error: {
                  length: !item?.length ? item?.error?.length : '',
                  startIndex: !item?.startIndex ? item?.error?.startIndex : ''
                }
              }
            : item
        );
        setHeaderDetails(updatedDetails1);
      }
    } else {
      setHeaderParameters({ ...headerParameters, [name]: value });
      setHeaderParametersError({ ...headerParametersError, [name]: '' });
    }
  };

  const onRemoveHandler = (id, isRemove = false) => {
    if (isRemove) {
      setHeaderParameters({ id: null, length: '', startIndex: '' });
    } else {
      const data = headerDetails.filter((item) => item?.id !== id);
      setHeaderDetails(data);
      if (data.length > 1) setShowDefaultFields(false);
    }
    const ids = selectMenu.filter((item) => item !== id);
    setSelectMenu(ids);
  };

  const formSubmithandler = (e) => {
    e.preventDefault();
    let brack = false;
    headerDetails.forEach((item) => {
      if (item?.error?.startIndex && item?.error?.length) {
        brack = true;
      }
    });
    if (brack) {
      addToast('Please fill all the fields!', {
        appearance: 'warning'
      });
      return false;
    }

    const key = 'error';
    headerDetails.map((obj) => {
      delete obj[key];
      return obj;
    });

    if (showDefaultFields) {
      const newErrors = {
        id: null,
        length: '',
        startIndex: ''
      };
      if (!headerParameters?.id) {
        newErrors.id = 'This field is required';
      }
      if (!headerParameters?.length) {
        newErrors.length = 'This field is required';
      }
      if (!headerParameters?.startIndex) {
        newErrors.startIndex = 'This field is required';
      }
      setHeaderParametersError(newErrors);

      if (
        headerParameters?.id &&
        headerParameters?.startIndex &&
        headerParameters?.length
      ) {
        const modifiedArray = finalFormData(
          [...headerDetails, headerParameters],
          'id',
          'headerId'
        );
        const newArrayWithSubjectedId = modifiedArray.map((obj) => ({
          ...obj,
          subjectTypeName: localStorage?.subjectTypes,
          isNewTemplate: JSON.parse(paramas?.nId)
        }));
        let obj = {
          templateId: templateHeader?.templateId,
          headerDetails: newArrayWithSubjectedId,
        };
        if (templateHeader?.headerDetails?.length > 0) {
          setConfirmModalOpen(true);
          setFormData(obj);
        } else {
          dispatch(updateTemplateHeaders(obj))
            .then((res) => {
              navigate('/templates');
              setIsLoading(false);
              setConfirmModalOpen(false);
              addToast('Headers Updated Successfully', {
                appearance: 'success'
              });
            })
            .catch((err) => {
              setIsLoading(false);
              addToast('Something went wrong!', {
                appearance: 'error'
              });
            });
        }
      }
    } else {
      const modifiedArray = finalFormData(
        [...headerDetails, headerParameters],
        'id',
        'headerId'
      );
      const newArrayWithSubjectedId = modifiedArray.map((obj) => ({
        ...obj,
        subjectTypeName: localStorage?.subjectTypes,
        isNewTemplate: JSON.parse(paramas?.nId)
      }));
      let obj = {
        templateId: templateHeader?.templateId,
        headerDetails: newArrayWithSubjectedId,
      };
      
      // Remove the last object from formData.headerDetails
      const updatedHeaderDetails = (obj as any).headerDetails?.slice(0, -1);
      const updatedFormData = {
        ...(obj as any),
        headerDetails: updatedHeaderDetails
      };
      if (templateHeader?.headerDetails?.length > 0) {
        setConfirmModalOpen(true);
        setFormData(updatedFormData);
      } else {
        dispatch(updateTemplateHeaders(obj))
          .then((res) => {
            navigate('/templates');
            setIsLoading(false);
            setConfirmModalOpen(false);
            addToast('Headers Updated Successfully', {
              appearance: 'success'
            });
          })
          .catch((err) => {
            setIsLoading(false);
            addToast('Something went wrong!', {
              appearance: 'error'
            });
          });
      }
    }
  };

  const handleModalSubmit = () => {
    setIsLoading(true);
    dispatch(updateTemplateHeaders(formData))
      .then((res) => {
        navigate('/templates');
        setIsLoading(false);
        setConfirmModalOpen(false);
        addToast('Headers Updated Successfully', {
          appearance: 'success'
        });
      })
      .catch((err) => {
        setIsLoading(false);
         addToast('Something went wrong!', {
           appearance: 'error'
         });
      });
  };

  const clearAllHandler = () => {
    setAddParameterButton(true);
    setHeaderDetails([]);
    setHeaderParameters({
      id: null,
      length: '',
      startIndex: '',
      headerName: ''
    });
    setShowDefaultFields(true);
  };

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg" sx={{ marginY: '20px' }}>
        <form onSubmit={formSubmithandler}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: {
                    xs: '45px 70px',
                    md: '45px 80px'
                  },
                  [theme.breakpoints.up('md')]: {
                    padding: '45px 80px'
                  }
                }}
              >
                {cardLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box>
                    {templateHeader?.headerDetails?.length >= 1 && isDisabled && (
                      <Box
                        onClick={() => setIsDisabled(false)}
                        sx={{
                          display: 'none',
                          justifyContent: 'end',
                          alignItems: 'center',
                          marginBottom: '10px',
                          cursor: 'pointer'
                        }}
                      >
                        <EditIcon />
                      </Box>
                    )}
                    {/* Template Name */}
                    <TemplateNameCommon show={true}/>

                    {/* Header Parameters  */}
                    <HeaderFieldsLabel />

                    {addParametersButton && headerDetails?.length === 0 ? (
                      <Grid container paddingY={1} paddingRight={0}>
                        <Box
                          marginTop={6}
                          sx={{
                            width: '100%',
                            border: 'none',
                            height: '50px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: '#D9D9D9',
                            borderRadius: '8px',
                            cursor: 'pointer'
                            // padding: '5px 20px'
                          }}
                          onClick={() => setAddParameterButton(false)}
                        >
                          <AddIcon fontSize="medium" />{' '}
                          <h3>Tap here to add parameters in Header Data</h3>
                        </Box>
                      </Grid>
                    ) : (
                      // Loop Form data
                      <Grid container marginTop={2}>
                        {headerDetails?.map((item, startIndex) => (
                          <>
                            <FilledTemplateFields
                              onSelectId={onSelectId}
                              item={item}
                              startIndex={startIndex}
                              select={entityList}
                              selectMenu={selectMenu}
                              onChangeHandler={onChangeHandler}
                              onRemoveHandler={onRemoveHandler}
                              showDefaultFields={showDefaultFields}
                              length={headerDetails?.length}
                              setShowDefaultFields={setShowDefaultFields}
                              isDisabled={isDisabled}
                            />
                          </>
                        ))}

                        {/* Default Form */}
                        {entityList?.length > headerDetails?.length && (
                          <>
                            <DefaultTemplatefields
                              headerParameters={headerParameters}
                              headerParametersError={headerParametersError}
                              onSelectId={onSelectId}
                              select={entityList}
                              selectMenu={selectMenu}
                              onChangeHandler={onChangeHandler}
                              headerDetails={headerDetails}
                              onRemoveHandler={onRemoveHandler}
                              addHeaderData={addHeaderData}
                              showDefaultFields={showDefaultFields}
                              setShowDefaultFields={setShowDefaultFields}
                            />
                          </>
                        )}
                      </Grid>
                    )}
                    <Box
                      sx={{
                        marginTop: '60px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {templateHeader?.headerDetails?.length >= 1 &&
                        isDisabled && (
                          <>
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="contained"
                              onClick={() => setIsDisabled(false)}
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="outlined"
                              onClick={() => navigate('/templates')}
                              startIcon={<CloseIcon />}
                            >
                              Close
                            </Button>
                          </>
                        )}
                      {!isDisabled && (
                        <>
                          {((headerParameters?.id &&
                            headerParameters?.startIndex &&
                            headerParameters?.length) ||
                            headerDetails?.length >= 1) && (
                            <Button
                              sx={{ marginRight: '15px', width: 100 }}
                              variant="outlined"
                              onClick={clearAllHandler}
                            >
                              Clear All
                            </Button>
                          )}
                          <Box>
                            {((headerParameters?.id &&
                              headerParameters?.startIndex &&
                              headerParameters?.length) ||
                              headerDetails?.length > 0) && (
                              <Button
                                variant="contained"
                                type="submit"
                                sx={{ width: 100 }}
                              >
                                Save
                              </Button>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
          </Grid>
        </form>
        <ConfirmationModal
          confirmModalOpen={confirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          handleModalSubmit={handleModalSubmit}
          loading={isLoading}
        />
      </Container>
    </>
  );
};

export default ManageHeader;
