import { Box, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';

const TemplateNameCommon = ({ show }) => {
  const [parametersName, setParameterName] = useState(
    JSON.parse(localStorage.getItem('paramId'))?.name
  );
  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid md={4} paddingRight={4} xs={12} xl={3} marginY={2}>
          {' '}
          <Box
            sx={{
              border: 'none',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '700'
            }}
          >
            <h3
              style={{ fontFamily: 'Inter, -apple-system, BlinkMacSystemFont' }}
            >
              Template Name
            </h3>
          </Box>
        </Grid>
        <Grid
          md={8}
          xs={12}
          xl={9}
          marginY={2}
          paddingRight={0}
          sx={{ cursor: 'not-allowed !important' }}
        >
          <TextField
            disabled={true}
            fullWidth
            value={parametersName}
            className="custom-design"
          />
        </Grid>
        {show && (
          <>
            <Grid md={4} paddingRight={4} xs={12} xl={3} marginY={2}>
              {' '}
              <Box
                sx={{
                  border: 'none',
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '700'
                }}
              >
                <h3
                  style={{
                    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont'
                  }}
                >
                  Subject Type
                </h3>
              </Box>
            </Grid>
            <Grid
              md={8}
              xs={12}
              xl={9}
              marginY={2}
              paddingRight={0}
              sx={{ cursor: 'not-allowed !important' }}
            >
              <TextField
                disabled={true}
                fullWidth
                value={localStorage?.subjectTypes}
                className="custom-design"
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default TemplateNameCommon;
