import { Button, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import { button } from './Button';

const CommonButton: FC<button> = (props) => {
  const {
    onClick,
    text,
    disabled = false,
    variant,
    loading = false,
    color,
    size,
    type,
    sx
  } = props;
  
  return (
    <div>
      <Button
        disabled={disabled}
        variant={variant}
        color={color}
        onClick={onClick}
        size={size}
        type={type}
        // sx={sx}
      >
        {loading ? (
          <CircularProgress
            size={25}
            color={variant === 'outlined' ? color : 'inherit'}
          />
        ) : (
          <span>{text}</span>
        )}
      </Button>
    </div>
  );
};

export default CommonButton;
